import { Text } from '@components/NavigationBar/components/SupportButton/styles'
import { theme } from '@damen/ui'
import styled from 'styled-components'

export const TableAndDesktopText = styled(Text)`
	display: none;

	@media ${theme.breakpoints.lg} {
		display: inline-block;
	}
`
