import styled from 'styled-components'
import { theme } from '@damen/ui'
import { CardElementProps } from './types'

export const CardElement = styled.div<CardElementProps>`
	position: relative;
	display: block;
	width: 100%;
	padding: ${(props) =>
		props.noBottomPadding ? `32px 24px 3px` : `32px 24px`};
	margin-bottom: 32px;
	background-color: ${({ background }) =>
		background === 'blue' ? theme.colors.blue : theme.colors.white};
	border: 1px solid ${theme.colors.greyAccentLight};

	@media ${theme.breakpoints.sm} {
		padding: ${(props) =>
			props.noBottomPadding ? `56px 64px 3px` : `56px 64px`};
		margin-bottom: 56px;
	}
`
