import styled, { css } from 'styled-components'

import { Heading, theme } from '@damen/ui'

import { MODAL } from '@src/constants/zIndexes'

import { Wrapper as SidebarFormWrapper } from '@components/SidebarForm/styles'

import { SidebarWrapperProps } from './types'

export const Main = styled.div<SidebarWrapperProps>`
	pointer-events: none;
	z-index: ${MODAL};
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translateX(100%);
	transition-property: transform;
	transition-duration: ${theme.timing.default};
	transition-timing-function: ease-in-out;
	width: 100%;

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: translateX(0);
		`}
`

export const Header = styled.div`
	text-align: right;
	padding: 20px 40px;
	flex-grow: 0;
	flex-shrink: 0;

	@media ${theme.breakpoints.mdMax} {
		padding: 10px 20px;
	}
`

export const Wrapper = styled.div<SidebarWrapperProps>`
	max-width: ${({ isNarrow, desktopWidth = 600 }) =>
		isNarrow ? '386px' : `${desktopWidth}px`};
	background-color: ${theme.colors.white};
	z-index: ${MODAL + 1};
	display: flex;
	flex-direction: column;
	position: relative;
	pointer-events: none;
	transform: translateX(100%);
	transition-property: transform;
	transition-duration: ${theme.timing.default};
	transition-timing-function: ease-in-out;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;

	${SidebarFormWrapper} > form {
		overflow: hidden;
	}

	${({ isOpen }) =>
		isOpen &&
		css`
			transform: translateX(0);
			pointer-events: auto;
		`}

	${({ isNarrow }) =>
		!isNarrow &&
		css`
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			height: 100%;
		`}

	@media ${theme.breakpoints.mdMax} {
		width: 100%;
		max-width: 414px;
	}

	@media ${theme.breakpoints.md} {
		width: 80%;
	}
`

export const ExtraPanelWrapper = styled(Wrapper)`
	display: flex;
	background-color: ${theme.colors.blueIce};

	@media ${theme.breakpoints.mdMax} {
		${Header} {
			display: block;
		}
	}

	@media ${theme.breakpoints.md} {
		overflow-y: auto;

		${Header} {
			display: none;
		}

		${({ isOpen }) =>
			isOpen &&
			css`
				transform: translateX(-100%);
			`}
	}
`

export const ExtraPanelBody = styled.div`
	padding: 32px 20px;

	@media ${theme.breakpoints.md} {
		padding: 100px 40px 40px;
	}
`

export const CloseButton = styled.button`
	cursor: pointer;
	border: none;
	background-color: transparent;
	padding: 8px 11px;
	position: relative;

	&::before {
		content: '';
		background-color: ${theme.colors.marineBlack};
		opacity: 0.2;
		border-radius: 100%;
		width: 32px;
		height: 32px;
		position: absolute;
		display: block;
		top: 5px;
		left: 4px;
		transform: scale3d(0, 0, 0);
		transition: transform 400ms ease 0s;
	}

	svg {
		position: relative;
	}

	&:focus,
	&:hover {
		&::before {
			transform: scale3d(1, 1, 1);
		}
	}
`

export const Title = styled(Heading.HeadingFour)`
	margin-bottom: 56px;
	color: ${theme.colors.marineBlack};
`
