import React from 'react'
import { Icon, theme } from '@damen/ui'
import { useHeaderContext } from '@contexts/HeaderContext'

import { getQueryParamsForPath } from '@utils/getQueryParamsForPathName'
import { getPathNameFromUrl } from '@utils/getPathNameFromUrl'
import { getMappedLinkHref } from '@utils/linkResolver'
// import { getQueryParamsForPath } from '@utils/getQueryParamsForPath'
import { useRouter } from 'next/router'
import { HOME_NAME } from '@src/constants/Routes'
import { Wrapper, Label, BackLink } from './styles'

interface BreadcrumbsProps {
	items?: Record<string, unknown>
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
	const router = useRouter()
	const { parentLink } = useHeaderContext()

	const href = getPathNameFromUrl(getMappedLinkHref(parentLink?.full_slug))

	// Remove query params that are not part of the pathName to prevent them getting added as a query "?slug=my-fleet"
	const queryObj = getQueryParamsForPath(href, router)

	return (
		<Wrapper>
			{parentLink && parentLink?.full_slug !== HOME_NAME && (
				<BackLink
					href={{
						pathname: href,
						query: queryObj
					}}
					replace
				>
					<Icon.LeftChevron
						width={11}
						height={16}
						fill={theme.colors.blue}
					/>
					{parentLink?.name && <Label>{parentLink.name}</Label>}
				</BackLink>
			)}
		</Wrapper>
	)
}

export default Breadcrumbs
