import styled, { css } from 'styled-components'

import { theme } from '@damen/ui'

import { ComponentWrapper } from '@components/Spacer/styles'
import ContainerComponent from '@components/Container/Container'

import {
	FormRowProps,
	FormFieldHorizontalProps,
	FormDividerProps,
	FormSummaryListProps,
	FormSummaryHeaderProps
} from './types'

const DEFAULT_DT_WIDTH = 168
const DEFAULT_DT_MARGIN_RIGHT = 63

export const Container = styled(ContainerComponent)`
	padding-bottom: 48px;
	padding-top: 48px;

	@media ${theme.breakpoints.sm} {
		padding-bottom: 0;
		padding-top: 0;
	}
`

export const StyledSpacer = styled(ComponentWrapper)`
	background-color: ${theme.colors.blueIce};

	@media ${theme.breakpoints.sm} {
		padding: 64px 0;
	}
`

export const FormWrapper = styled.div``

export const FormRow = styled.div<FormRowProps>`
	width: 100%;
	margin-bottom: ${(props) => (props?.doubleSpacing ? `40px` : `24px`)};

	// NOTE: Reduce font size for input field
	input,
	textarea {
		font-size: 14px;
		letter-spacing: 0.6px;
		line-height: 14px;
	}

	input:placeholder-shown {
		text-overflow: ellipsis;
	}

	@media ${theme.breakpoints.md} {
		${(props) =>
			props.widthSize ? `width: ${props.widthSize};` : 'width: 100%;'}

		${({ noBottomMargin = false }) =>
			noBottomMargin &&
			css`
				margin-bottom: 0;
			`}
	}
`

export const FormLabel = styled.label`
	position: relative;
	font-size: ${theme.typography.fontSizeFormLabel}px;
	line-height: 1;
	letter-spacing: 0.8px;
	font-family: ${theme.fonts.body};
	font-weight: 500;
	display: inline-block;
	margin-bottom: 12px;
	color: ${theme.colors.marineBlack} !important;
`

export const FormDescription = styled.p`
	color: ${theme.colors.grey};
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.2px;
	display: block;
`

export const FormText = styled.p`
	color: ${theme.colors.marineBlack};
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.2px;
	display: block;

	h3 + & {
		margin-top: -16px;
	}
`

export const FormTip = styled.p`
	color: ${theme.colors.grey};
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.2px;
	margin: 8px 0 0;
	display: block;
`

export const FormFieldHorizontal = styled.div<FormFieldHorizontalProps>`
	display: block;

	label {
		color: ${theme.colors.marineBlack};
	}

	@media ${theme.breakpoints.sm} {
		${(props) =>
			props.hasColumns === true
				? 'display: grid; gap: 16px;'
				: 'display: flex;'}
		${(props) =>
			props.hasColumns === true
				? 'grid-template-columns: repeat(2, 1fr);'
				: ''}
	}
`

export const FormSummaryList = styled.dl<FormSummaryListProps>`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	font-size: ${theme.typography.fontSizeTextSmall}px;
	line-height: 32px;
	letter-spacing: 0.2px;

	dt {
		font-family: ${theme.fonts.body};
		margin-right: ${DEFAULT_DT_MARGIN_RIGHT}px;
		margin-bottom: ${theme.layout.spacing};
		font-weight: 300;
		color: ${theme.colors.grey};
		width: 100%;
		line-height: 20px;
	}

	dd {
		width: 100%;
		display: inline-block;
		line-height: 20px;
		margin-bottom: ${theme.layout.spacingL};
	}

	p {
		margin-bottom: ${theme.layout.spacing};
	}

	button {
		font-size: 14px;
		line-height: 1em;

		span:last-child {
			margin-left: 2px;
		}
	}

	span {
		&:first-child {
			&::before {
				display: none;
			}
		}
	}

	@media ${theme.breakpoints.md} {
		flex-direction: row;

		dt {
			width: ${({ dtWidth }) => `${dtWidth ?? DEFAULT_DT_WIDTH}`}px;
			margin-bottom: ${theme.layout.spacingL};
		}

		dd {
			width: ${({ dtWidth }) =>
				`calc(100% - ${
					(dtWidth ?? DEFAULT_DT_WIDTH) + DEFAULT_DT_MARGIN_RIGHT
				}px)`};
		}

		span {
			display: block;

			&::before {
				display: none;
			}
		}
	}
`

export const FormHeading = styled.h3`
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.7px;
	color: ${theme.colors.marineBlack};
	margin: 0 0 16px;
`

export const FormSubheading = styled.h4`
	font-size: 14px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.7px;
	color: ${theme.colors.marineBlack};
	margin: 32px 0 16px;
`

export const FormMessage = styled.div`
	color: ${theme.colors.marineBlack};
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0.2px;
	font-weight: 300;
`

export const SummaryHeader = styled.div<FormSummaryHeaderProps>`
	margin-bottom: ${({ marginBottom }) => `${marginBottom ?? 32}px`};
	display: flex;
	justify-content: space-between;

	+ ${FormSubheading} {
		margin-top: 0;
	}

	+ ${FormText} {
		margin-top: -48px;
		margin-bottom: 32px;
	}

	> button {
		margin: 6px 0 auto auto;
	}
`

export const FormSummaryHeader = styled.header`
	margin-bottom: 16px;
`

export const FormSummaryBlock = styled.div`
	margin-bottom: 32px;
`

export const FormDivider = styled.div<FormDividerProps>`
	display: block;
	padding: ${({ hasNoTopMargin = true }) =>
		hasNoTopMargin ? '0 0 32px' : '32px 0'};

	&::before {
		content: '';
		display: block;
		position: relative;
		width: 100%;
		height: 1px;
		background-color: ${theme.colors.greyAccentLight};
	}
`
