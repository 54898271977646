import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'

interface ContainerProps {
	children: any
	className?: string
	small?: boolean
}

const ContainerElement = styled.div<ContainerProps>`
	position: relative;
	width: 100%;
	padding: 0 1rem;
	margin: 0 auto;
	max-width: ${({ small }) => (small === true ? '792px' : '1188px')};

	@media ${theme.breakpoints.xl} {
		padding: 0;
	}
`

const Container: FC<ContainerProps> = ({ children, ...rest }) => (
	<ContainerElement {...rest}>{children}</ContainerElement>
)

export default Container
