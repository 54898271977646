import styled from 'styled-components'

import { theme } from '@damen/ui'
import Link from 'next/link'

export const Wrapper = styled.div`
	align-self: stretch;

	@media ${theme.breakpoints.smMax} {
		width: 100%;
	}
`

export const Label = styled.span`
	opacity: 0;
	max-width: 0;
	transition: max-width 0.2s, opacity 0.2s, transform 0.2s;
	white-space: nowrap;
	font-size: 16px;
	font-weight: 500;
	line-height: 16px;
	letter-spacing: 0.5px;
	color: ${theme.colors.blue};

	@media ${theme.breakpoints.sm} {
		overflow: hidden;
	}

	@media ${theme.breakpoints.smMax} {
		flex: 0 1 auto;
		opacity: 1;
		max-width: none;
	}
`

export const BackLink = styled(Link)`
	cursor: pointer;
	display: flex;
	align-items: center;
	border: none;
	background-color: ${theme.colors.greyLight};
	height: 100%;
	max-width: 80px;
	padding: 0 35px;
	transition: max-width 0.2s, padding 0.2s;

	&:hover {
		padding-right: 56px;

		&,
		${Label} {
			max-width: 300px;
		}

		${Label} {
			opacity: 1;
			transform: translateX(16px);
		}
	}

	svg {
		flex: 1 0 11px;
		transform: rotate(180deg);
	}

	@media ${theme.breakpoints.smMax} {
		height: 64px;
		max-width: none;
		width: 100%;
		text-align: left;
		padding: 0 24px;

		&:focus,
		&:hover {
			max-width: 100%;
		}

		svg {
			flex: 0 1 auto;
			margin-right: 16px;
		}
	}

	@media (pointer: coarse) {
		min-width: 80px;

		&:focus,
		&:hover {
			padding-right: 35px;

			&,
			${Label} {
				max-width: 0;
			}

			${Label} {
				opacity: 1;
				transform: translateX(0);
			}
		}
	}

	@media (pointer: coarse) and (${theme.breakpoints.smMax}) {
		transition: none;
		max-width: 100%;

		&:focus,
		&:hover {
			width: 100%;
			max-width: 100%;
		}
	}
`
