import React, { useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { SessionProvider } from 'next-auth/react'

import { theme } from '@damen/ui'

import { AuthenticatedApolloProvider } from '@lib/apollo/AuthenticatedApolloProvider'
import { IS_PROD, pageview as gtagPageView } from '@lib/gtag'
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js'

import ApplicationInsightsTracker from '@components/ApplicationInsightsTracker'
import Authentication from '@components/Authentication'
import Layout from '@components/Layout/Layout'

import { createApplicationInsights } from '@utils/appInsights'
import { useOnRouteChangeComplete } from '@hooks/useOnRouteChange'
import LanguageRedirect from '@components/LanguageRedirect'

declare global {
	interface Window {
		storyblok: any
	}
}

type MyAppProps = {
	Component: any
	pageProps: any
}

const MyApp: React.FC<MyAppProps> = ({ Component, pageProps }) => {
	const { session } = pageProps

	useOnRouteChangeComplete((url) => {
		if (!IS_PROD) {
			return
		}
		gtagPageView(url)
	})

	const { reactPlugin } = useMemo(() => createApplicationInsights(), [])

	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<ApplicationInsightsTracker>
				<SessionProvider
					session={session}
					refetchInterval={
						process.env.NEXT_PUBLIC_SESSION_REFETCH_TIMER
							? Number(
									process.env
										.NEXT_PUBLIC_SESSION_REFETCH_TIMER
							  )
							: undefined
					}
				>
					<AuthenticatedApolloProvider pageProps={pageProps}>
						<ThemeProvider theme={theme}>
							<Authentication>
								<LanguageRedirect>
									<Layout>
										<Component {...pageProps} />
									</Layout>
								</LanguageRedirect>
							</Authentication>
						</ThemeProvider>
					</AuthenticatedApolloProvider>
				</SessionProvider>
			</ApplicationInsightsTracker>
		</AppInsightsContext.Provider>
	)
}

export default MyApp
