import React from 'react'
import { Icon, theme } from '@damen/ui'

import { useHeaderContext } from '@contexts/HeaderContext'

import MobileQuotationNotificatiton from '@components/NavigationBar/components/MobileQuotationRequestButton'

import useWindowSize from '@hooks/useWindowSize'
import {
	MenuButtonWrapper,
	MenuIcon,
	QuotationRequestButtonWrapper
} from './styles'

interface MenuButtonProps {
	color?: 'white' | 'blue'
}

const MenuButton: React.FC<MenuButtonProps> = ({ color = 'white' }) => {
	// Contexts
	const { menuToggle } = useHeaderContext()
	const { width } = useWindowSize()

	// Properts
	const textColor = color === 'white' ? theme.colors.blue : theme.colors.white
	const backgroundColor =
		color === 'white' ? theme.colors.white : theme.colors.blue

	return (
		<>
			<MenuButtonWrapper color={backgroundColor} onClick={menuToggle}>
				<MenuIcon color={textColor} />
				<Icon.LogoDamen fill={textColor} height={16} />
			</MenuButtonWrapper>
			<QuotationRequestButtonWrapper>
				<MobileQuotationNotificatiton
					color={
						width < theme.breakpointsNumerical.sm
							? textColor
							: backgroundColor
					}
					iconSize={width < theme.breakpointsNumerical.sm ? 24 : 32}
				/>
			</QuotationRequestButtonWrapper>
		</>
	)
}

export default MenuButton
