import { MultilinkStoryblok } from '@graphql/storyblokcomponents'

const removeLanguageFolder = (url: string) => {
	return url.replace(/^\/(en|fr-fr|es-es)\//, '')
}

export const getMappedLinkHref = (url: string) => {
	return url?.replace('home/', '/').replaceAll('//', '/')
}

export const getStoryblokLinkHref = (link: MultilinkStoryblok) => {
	switch (link.linktype) {
		case 'story':
			return getMappedLinkHref(
				removeLanguageFolder(link?.story?.full_slug ?? link.cached_url)
			)
		case 'url':
			return getMappedLinkHref(link.url)
		case 'asset':
			return link.url
		case 'email':
			return link.email
		default:
			return null
	}
}
