import React, { useCallback, useState } from 'react'

import { Icon, theme } from '@damen/ui'
import { useGetDamenContactsQuery } from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'

import useWindowSize from '@hooks/useWindowSize'
import ContactListSkeleton from '@skeletons/ContactList'
import ErrorState from '@components/UI/Error'
import ContactDetails from '@components/ContactDetails'

import { ContactDetailsVariant } from '@components/ContactDetails/types'

import {
	ExpandingTitle,
	Title,
	PhoneNumber,
	ContactInfoContainer,
	ClickablePhoneNumber
} from './styles'

import { ContactInfoProps, ConditionalContainerProps } from './types'

const ConditionalContainer: React.FC<ConditionalContainerProps> = ({
	isOpen,
	children
}) => {
	const { width } = useWindowSize()

	return width < theme.breakpointsNumerical.md ? (
		<ContactInfoContainer
			initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
			animate={{
				height: isOpen ? 'auto' : 0,
				opacity: isOpen ? 1 : 0,
				overflow: isOpen ? 'visible' : 'hidden'
			}}
			transition={{ duration: theme.timing.numDefault / 1000 }}
		>
			{children}
		</ContactInfoContainer>
	) : (
		<ContactInfoContainer
			initial={{ height: 'auto', opacity: 1, overflow: 'visible' }}
		>
			{children}
		</ContactInfoContainer>
	)
}

const ContactInfo: React.FC<ContactInfoProps> = ({
	phoneNumber,
	supportTitle,
	title,
	labelContact,
	errorContactsDescription
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const { locale } = useLocale()

	const {
		data: damenContactsData,
		loading,
		error
	} = useGetDamenContactsQuery({
		variables: {
			skip: 0,
			limit: 10,
			language: locale
		}
	})

	const items = damenContactsData?.damenContacts?.items

	const contactClickHandler = useCallback(() => {
		setIsOpen((previous) => !previous)
	}, [setIsOpen])

	return (
		<div>
			<Title>{title}</Title>
			<PhoneNumber>
				<Icon.PhoneAlt
					width={20}
					height={20}
					fill={theme.colors.marineBlack}
				/>
				<ClickablePhoneNumber href={`tel://${phoneNumber}`}>
					{phoneNumber}
				</ClickablePhoneNumber>
			</PhoneNumber>
			<ExpandingTitle onClick={contactClickHandler} isOpen={isOpen}>
				<span>{supportTitle}</span>
				<Icon.CaretDown
					width={14}
					height={14}
					fill={theme.colors.marineBlack}
				/>
			</ExpandingTitle>
			<ConditionalContainer isOpen={isOpen}>
				{loading && !error && <ContactListSkeleton />}
				{error && !loading && (
					<ErrorState
						content={
							errorContactsDescription ??
							'Something went wrong while retrieving contacts'
						}
						showBowWave
					/>
				)}
				{!loading &&
					!error &&
					items?.map((item) => {
						return (
							<ContactDetails
								key={item.id}
								contact={item}
								buttonLabel={labelContact}
								variant={ContactDetailsVariant.IMAGELESS}
							/>
						)
					})}
			</ConditionalContainer>
		</div>
	)
}

export default ContactInfo
