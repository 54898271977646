import React from 'react'

import { HeaderProvider } from '@contexts/HeaderContext'
import { NotificationProvider } from '@contexts/NotificationContext'
import TranslationProvider from '@contexts/TranslationContext'

import Header from '@components/Header'
import Menu from '@components/Menu'
import Notifications from '@components/Notifications'
import NewPartRequestCartProvider from '@contexts/NewPartRequestCartContext'

import { useGetMenuItemsQuery } from '@graphql/graphql'
import { useLocale } from '@hooks/useLocale'
import { LayoutProps } from './types'

const Body: React.FC<LayoutProps> = ({ children }) => {
	const { locale } = useLocale()
	const { data, loading, error } = useGetMenuItemsQuery({
		variables: {
			language: locale
		},
		fetchPolicy: 'cache-first'
	})

	return (
		<>
			<Menu data={data} loading={loading} error={error} />
			<Header />
			<Notifications />
			<main>{children}</main>
		</>
	)
}

const Layout: React.FC<LayoutProps> = ({ children }) => (
	<TranslationProvider>
		<NewPartRequestCartProvider>
			<HeaderProvider>
				<NotificationProvider>
					<Body>{children}</Body>
				</NotificationProvider>
			</HeaderProvider>
		</NewPartRequestCartProvider>
	</TranslationProvider>
)

export default Layout
