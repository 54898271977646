import styled from 'styled-components'

interface Props {
	color: string
}

export const MenuButtonWrapper = styled.button<Props>`
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 32px 30px 32px 40px;
	background-color: ${({ color }) => color};
	border: none;
	transition: background 0.2s;
	margin: 0;

	button {
		top: 2px;
	}

	button,
	span {
		pointer-events: none;
	}

	@media ${({ theme }) => theme.breakpoints.mdMax} {
		width: 100%;
		padding: 24px;
	}
`

export const MenuIcon = styled.span<Props>`
	position: relative;
	display: inline-block;
	margin-right: 20px;

	&,
	&::before,
	&::after {
		height: 4px;
		width: 20px;
		background-color: ${({ color }) => color};
		transition: background 0.2s;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		display: inline-block;
		left: 0;
	}

	&::before {
		content: '';
		display: inline-block;
		top: -2px;
		transform: translateY(-100%);
	}

	&::after {
		content: '';
		display: inline-block;
		bottom: -2px;
		transform: translateY(100%);
	}
`

export const QuotationRequestButtonWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;

	@media ${({ theme }) => theme.breakpoints.md} {
		display: none;
	}
`
